<div class="card-item notification-item" [class.notification-item-read]="notification.isRead">
	<header class="card-item-head notification-item-head">
		<div>
			<span class="badge badge-{{ notification | notificationStatus : 'badgeClass' }}">{{ ('TopBar.Notifications.Categories.' + notification.category) | translate }} </span>
		</div>
		<div class="card-item-date">{{ notification.creationDate | dateFormat:'WithHours' }}</div>
	</header>
	<div class="card-item-body notification-item-body">
		<span class="name">{{ notification | notificationRelatedEntity }}</span>
		<div class="notification-item-content-head">
			<notification-item-avatar [notification]="notification"/>

			<!-- TODO: Invoice info -->
			<!-- <div class="notification-item-link"><a href="">{{ 'TopBar.Notifications.Invoice' | translate : { id : 78906 } }}</a></div>  -->
		</div>
		
		<!-- TODO: Coverage rate info -->
		<!-- <div>
			<span class="notification-item-rate-label" translate="TopBar.Notifications.Rate"></span><span class="notification-item-rate-value"> 2%</span>
		</div> -->
	</div>
</div>
