<div *ngIf="(onLine$ | async) !== true" class="offline-alert text-center p-2" translate="NotificationMessages.Errors.Offline"></div>
<div *ngIf="ws.connectWarning$ | async" class="offline-alert ws-offline-alert text-center p-2 cursor-pointer" translate="NotificationMessages.Errors.OfflineWebSocket" (click)="location.reload()"></div>

<div *ngIf="(appIsReady$ | async) === false" class="loader-standalone-wrapper">
    <div class="loader loader-standalone">
        <svg class="loader-spinner" viewBox="0 0 50 50"><circle class="loader-spinner-path" cx="25" cy="25" fill="none" r="20" stroke-width="5"></circle></svg>
        <div *ngIf="bootstrapError$ | async" translate="Errors.UnknownServer"></div>
    </div>
    <i class="fa"></i><i class="fac"></i>
</div>

<ng-container *ngIf="appIsReady$ | async">
    <div class="{{layoutName}}-page">
        <router-outlet></router-outlet>
    </div>

    <div class="page-loader" [class.is-loading]="pageLoader.isLoading$ | async">
        <loader-spinner [model]="pageLoader"/>
    </div>

    <page-anchors [anchors]="pageAnchors"/>

    <div class="page-notifications">
        <notification-message *ngFor="let notification of notifications; let index = index" [notification]="notification"
                            (dismissEvent)="removeNotification(index)"/>
    </div>
</ng-container>
