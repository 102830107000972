/* eslint-disable @angular-eslint/sort-ngmodule-metadata-arrays */
import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { fr as dateLocaleFr } from 'date-fns/locale/fr';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Store } from '@ngrx/store';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AbstractLoggerService } from 'angular-auth-oidc-client';
import { CTX_HELP_CONNECTOR, ContextHelpModule } from '@aston/context-help';
import { AppBaseConfiguration, AppMissingTranslationHandler, FactorConfigurationService, LoaderSpinnerComponent, NotificationMessageComponent, PageAnchorsComponent, TelemetryInitializer, ValidationService, registerDateLocale } from '@aston/foundation';
import { APP_HOME_URL, provideFoundationServices } from '@aston/foundation/provider-bundles';
import { BusinessThemeService, CSSThemeService, DefaultBusinessThemeService, ThemesModule } from '@aston/themes';
import { FeatureFlagsModule, FeatureFlagsService } from '@aston/feature-flags';
import { ALLIANZ_CONNECTOR, AllianzTradeModule } from '@aston/allianz-trade';
import { EllisphereModule, ELLISPHERE_CONNECTOR } from '@aston/ellisphere';
import { userPrefsFiltersConnector } from '@aston/user-prefs';
import { USER_FILTERS_CONNECTOR } from '@aston/filters';
import { provideNgxMask } from 'ngx-mask';
import { provideRouter, withViewTransitions } from '@angular/router';

import themes from '../assets/sass/themes';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppConfiguration } from './app.configuration';
import { AuthenticationModule } from './authentication-module/authentication.module';
import { RootStoreModule } from './root-store';
import { AppConstants, AppConstants as MiddleAppConstants } from './app.constants';
import { MIDDLE_API_ROOT } from './shared-module/services/di.service';
import { MiddleFactorConfigurationService } from './shared-module/services/factor-configuration.service';
import { TelemetryAuthLoggerService } from './shared-module/services/telemetry-auth-logger.service';
import { MiddleTelemetryInitializer } from './shared-module/services/telemetry-initializer.service';
import { MiddleValidationService } from './shared-module/services/validation.service';
import { WebSocketService } from './shared-module/services/websocket.service';
import { DunningActionTypePipe } from './shared-module/pipes';
import { allianzTradeConnector, apiRoot, appConfigurationFactory, ctxHelpConnector, ellisphereConnector, getBaseLocation, getHomeLocation, onViewTransitionCreated, provideDebugBar, providePipes, translateLoader } from './app.connectors';
import { ProductReferentialStore, provideProductReferentialStore } from './shared-module/stores/product-referential.store';


// override constants with our own
AppConstants.overrideWith(MiddleAppConstants);

// import the locale, used in currencyPipe for format
registerLocaleData(localeFr);
registerDateLocale('fr', dateLocaleFr);

@NgModule({
	bootstrap: [AppComponent],
	declarations: [AppComponent],
	imports: [
		AppRoutingModule,
		AuthenticationModule,
		BrowserAnimationsModule,
		BrowserModule,
		TranslateModule.forRoot({
			missingTranslationHandler: {
				provide: MissingTranslationHandler,
				useClass: AppMissingTranslationHandler,
			},
			loader: {
				provide: TranslateLoader,
				useFactory: translateLoader,
				deps: [HttpClient, AppConfiguration]
			},
		}),
		FeatureFlagsModule.forRoot(),
		ThemesModule.forRoot({
			themes,
			cssThemeService: {
				provide: CSSThemeService,
				useClass: CSSThemeService
			},
			businessThemeService: {
				provide: BusinessThemeService,
				useClass: DefaultBusinessThemeService
			},
		}),
		ContextHelpModule,
		AllianzTradeModule,
		EllisphereModule,
		RootStoreModule,
		NotificationMessageComponent,
		LoaderSpinnerComponent,
		PageAnchorsComponent,
	],
	providers: [
		AppConfiguration,
		WebSocketService,
		DunningActionTypePipe, // needed in module resolution phase
		{
			provide: AppBaseConfiguration,
			useExisting: AppConfiguration
		},
		{
			provide: TelemetryInitializer,
			useClass: MiddleTelemetryInitializer
		},
		{
			provide: AbstractLoggerService,
			useClass: TelemetryAuthLoggerService
		},
		{
			provide: ValidationService,
			useClass: MiddleValidationService
		},
		{
			provide: FactorConfigurationService,
			useClass: MiddleFactorConfigurationService
		},
		{ provide: APP_BASE_HREF, useFactory: getBaseLocation },
		{ provide: MIDDLE_API_ROOT, useFactory: apiRoot, deps: [Store] },
		{ provide: APP_HOME_URL, useFactory: getHomeLocation, deps: [FeatureFlagsService] },
		{ provide: APP_INITIALIZER, useFactory: appConfigurationFactory, deps: [Store], multi: true },
		{ provide: CTX_HELP_CONNECTOR, useFactory: ctxHelpConnector, deps: [Store] },
		{ provide: ALLIANZ_CONNECTOR, useFactory: allianzTradeConnector, deps: [Store] },
		{ provide: ELLISPHERE_CONNECTOR, useFactory: ellisphereConnector, deps: [Store] },
		{ provide: USER_FILTERS_CONNECTOR, useFactory: userPrefsFiltersConnector, deps: [Store] },
		{ provide: ProductReferentialStore, useFactory: provideProductReferentialStore, deps: [FeatureFlagsService, Store] },
		provideRouter([], withViewTransitions({ skipInitialTransition: true, onViewTransitionCreated })),
		provideFoundationServices(),
		provideDebugBar(),
		provideNgxMask(),
		providePipes(),
		provideHttpClient(withInterceptorsFromDi()),
	]
})
export class AppModule {
}
