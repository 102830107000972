<div
    id="app"
    class="authenticated-layout"
    [class.menu-collapsed]="menuCollapsed"
    [class.notif-opened]="notificationsIsOpened"
    [class.has-unread-notifications]="notificationsHasUnread$ | async">

    <nav *featureFlag="'showACCMenu'; else itsmenu"
        id="menu"
        layout-menu-acc
        (action)="handleAction($event, 'menu')"
        [hasImportHistoryClearanceLevel]="hasImportHistoryClearanceLevel | async"
        [hasManualImportClearanceLevel]="hasManualImportClearanceLevel$ | async"
        [hasTeamFollowUpClearanceLevel]="hasTeamFollowUpClearanceLevel$ | async"
        [hasAdministrationToolsClearanceLevel]="hasAdministrationToolsClearanceLevel$ | async"
        [hasSuperAdministrationClearanceLevel]="hasSuperAdministrationClearanceLevel$ | async"
        [hasReadOnlyAccessToAllSuperDebtorsClearanceLevel]="hasReadOnlyAccessToAllSuperDebtorsClearanceLevel$ | async">
    </nav>

    <ng-template #itsmenu>
        <nav
            id="menu"
            layout-menu-its
            (action)="handleAction($event, 'menu')"
            [hasImportHistoryClearanceLevel]="hasImportHistoryClearanceLevel | async"
            [hasManualImportClearanceLevel]="hasManualImportClearanceLevel$ | async"
            [hasLetteringClearanceLevel]="hasLetteringClearanceLevel$ | async"
            [hasPaymentOrderClearanceLevel]="hasPaymentOrderClearanceLevel$ | async"
            [hasAdministrationToolsClearanceLevel]="hasAdministrationToolsClearanceLevel$ | async"
            [hasSuperAdministrationClearanceLevel]="hasSuperAdministrationClearanceLevel$ | async"
            [hasDunningClearanceLevel]="hasDunningClearanceLevel$ | async">
        </nav>
    </ng-template>

    <section id="content">
        <header id="top-bar" layout-top-bar [user]="currentUserAvatar$ | async" [currentLanguage]="(currentLanguage$ | async)?.language"
                [tenantName]="pageOverTitle$ | async"
                [tenantLogo]="pageOverLogo$ | async"
                [tenantConsoleId]="(tenant$ | async)?.portalId"
                (selectLanguage)="selectLanguage($event)" (logout)="logout()"
                (action)="handleAction($event)">
        </header>

        <main id="main" [class.overlay]="notificationsIsOpened" scrolledByContainer>
            <layout-breadcrumb/>
            <router-outlet/>
        </main>

        <side-panel
            (clickOutside)="closePanels()"
            exclude="#top-bar .btn-notifications, #top-bar"
            [open]="notificationsIsOpened || themesIsOpened">

            @if (notificationsIsOpened) {
                <notifications-panel
                    id="notifications"
                    class="notifications"
                    (action)="handleAction($event, 'notifications')"/>
            }

            @if (themesIsOpened) {
                <div class="theme-switcher-panel">
                    <h2 class="m-3" translate="App.Themes.Title"></h2>
                    <theme-switcher (selectTheme)="useTheme($event)"/>
                </div>
            }
        </side-panel>
    </section>

</div>

<debug-bar/>
