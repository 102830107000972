import { filter, map, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router, ActivatedRouteSnapshot, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OnInit, Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

import {
	LanguageService,
	PageService,
	PageLoaderService,
	IPageLoader,
	NotificationService
} from '../../services';
import { INotification } from '../../models';
import { Loader, FactorConfigurationService } from '../../classes';
import { useDefaultTimezone } from '../../functions';
import { AppConstants } from '../../config';


@Directive()
export class AppBaseComponent implements OnInit, OnDestroy {

	protected routeSnapshot!: ActivatedRouteSnapshot;

	layoutName = '';
	pageLoader = new Loader();
	notifications: INotification[] = [];

	mainScrollableElement!: HTMLElement;
	destroySubscriptions$: Subject<boolean> = new Subject<boolean>();

	constructor(
		protected translateService: TranslateService,
		protected languageService: LanguageService,
		protected pageService: PageService,
		protected notificationService: NotificationService,
		protected pageLoaderService: PageLoaderService,
		protected route: ActivatedRoute,
		protected router: Router,
		protected factorConfigService: FactorConfigurationService) {
		// super();
		this.translateService.setDefaultLang(this.languageService.getCurrentLanguage());

		this.languageService.language$
			.pipe(takeUntil(this.destroySubscriptions$))
			.subscribe(current => {
				this.translateService.use(current);
			});

		this.translateService.onLangChange
			.pipe(takeUntil(this.destroySubscriptions$))
			.subscribe(_ => {
				this.setTitle();
			});

		factorConfigService.getFactorConfig()
			.pipe(takeUntil(this.destroySubscriptions$))
			.subscribe(config => {
				useDefaultTimezone(config.apiTimeZone);
			});
	}

	ngOnInit() {
		this.router.events.pipe(
			filter(event => event instanceof NavigationEnd),
			map(_ => this.findCurrentPageComponent()),
			distinctUntilChanged(),
			takeUntil(this.destroySubscriptions$)
		).subscribe(route => {
			this.routeSnapshot = route;
			this.setLayout();
		});

		this.pageLoaderService.loader$
			.pipe(takeUntil(this.destroySubscriptions$))
			.subscribe((model: IPageLoader) => {
				this.pageLoader.reset(model.isLoading);
			});

		this.notificationService.notifications$
			.pipe(takeUntil(this.destroySubscriptions$))
			.subscribe(notifications => {
				window.setTimeout(() => {
					this.notifications = notifications;
				});
			});
	}

	ngOnDestroy() {
		this.destroySubscriptions$.next(true);
		this.destroySubscriptions$.unsubscribe();
	}

	findCurrentPageComponent() {
		let inner = this.route.snapshot;
		if (inner) while (inner.children.length !== 0) {
			inner = inner.children[0];
			this.layoutName = inner.data['layoutName'];
		}
		return inner;
	}

	setLayout() {
		this.setTitle();

		this.layoutName = this.routeSnapshot?.data['layoutName'] || '';

		if (typeof AppConstants.ANIMATED_SCROLL_ELEMENT_DEFAULT === 'string') {
			this.mainScrollableElement = document.querySelector(<string>AppConstants.ANIMATED_SCROLL_ELEMENT_DEFAULT) as HTMLElement;

			if (this.mainScrollableElement?.scrollTo) {
				this.mainScrollableElement.scrollTo(0, 0);
			}
		} else {
			window.scrollTo(0, 0);
		}
	}

	setTitle() {
		if (!this.routeSnapshot || !this.routeSnapshot.data['titleTranslationKey']) { return; }
		this.pageService.setTitle(this.routeSnapshot.data['titleTranslationKey'], this.routeSnapshot.params);
	}

	removeNotification(index: number) {
		this.notificationService.remove(index);
	}
}
