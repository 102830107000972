import { ISearchItem } from '@aston/foundation';
import { searchForCategoryProvider } from '@aston/multi-search-input';
import * as Routes from 'apps/middle/src/app/routes-definitions';
import { Injector } from '@angular/core';

import { SearchResultCategory } from '../../shared-module/enums';
import { AppStoreActions } from '../../root-store/app-store';
import { SellersListStore } from '../store/sellers-list/list-store';

import { SellerListFilterSpecService } from './sellers-list-filter-spec.service';
import { SellersListService } from './sellers-list.service';

export const sellerSearchProvider = searchForCategoryProvider(
	SearchResultCategory.Seller,
	Routes.getSellersListFullPath(),
	(item: ISearchItem) => Routes.getSellerDetailIdentityFullPath(item.value),
	AppStoreActions,
	(injector: Injector) => injector.get(SellersListStore).provideSearchActions(),
	SellersListService,
	SellerListFilterSpecService,
);
