
import { Component, Output, EventEmitter, Input } from '@angular/core';
import { ActionType, StoreLoader, IAction, LoaderSpinnerComponent } from '@aston/foundation';
import { AsyncPipe, NgFor } from '@angular/common';

import { INotificationItem, INotificationsList } from '../../models';
import { NotificationItemComponent } from '../notification-item/notification-item.component';

@Component({
	selector: 'notifications-list',
	templateUrl: './notifications-list.component.html',
	standalone: true,
	imports: [NgFor, LoaderSpinnerComponent, NotificationItemComponent, AsyncPipe]
})
export class NotificationsListComponent {
	@Input() notifications: INotificationsList;
	@Input() loader = StoreLoader.empty();
	@Output() action: EventEmitter<IAction> = new EventEmitter();

	open(value: INotificationItem) {
		this.action.emit({ type: ActionType.SELECT, value });
	}
}
