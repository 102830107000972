
import { Component, OnInit, inject } from '@angular/core';
import * as RoutesDefinitions from 'apps/middle/src/app/routes-definitions';
import { AppConstants } from 'apps/middle/src/app/app.constants';
import { delay, takeUntil } from 'rxjs/operators';
import { LoaderSpinnerComponent } from '@aston/foundation/components';
import { APP_HOME_URL } from '@aston/foundation/provider-bundles';

import { LoginPageComponent } from '../login-page/login-page.component';

@Component({
	selector: 'login-callback-page',
	templateUrl: '../login-page/login-page.component.html',
	standalone: true,
	imports: [LoaderSpinnerComponent]

})
export class LoginCallbackPageComponent extends LoginPageComponent implements OnInit {

	private homeUrl: string = inject(APP_HOME_URL)

	ngOnInit() {
		const redirectUrlVar = AppConstants.LOCALSTORAGE_KEYS.REDIRECT_URL;

		this.activatedRoute.queryParams.pipe(
			takeUntil(this.destroySubscriptions$)
		).subscribe(params => {
			this.authenticationService.getIsAuthorized().pipe(delay(0)).subscribe(isAuth => {
				if (isAuth) {
					const queryParams = { [redirectUrlVar]: params[redirectUrlVar] };
					this.router.navigate([this.homeUrl], { queryParams });

				} else {
					this.router.navigate([RoutesDefinitions.getLoginFullPath()]);
				}
			});
		})
	}
}
