import { ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { Store } from '@ngrx/store';
import { FactorFeaturesStoreSelectors } from 'apps/middle/src/app/root-store/factor-features-store';
import { AsyncPipe, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { MenuComponent } from './menu.component';

@Component({
	selector: '[layout-menu-acc]',
	templateUrl: './menu-acc.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgIf, TranslateModule, RouterLink, RouterLinkActive, AsyncPipe]
})
export class MenuACCComponent extends MenuComponent implements OnInit {

	isImportMenuDisabled$ = this.store.select(FactorFeaturesStoreSelectors.selectIsImportMenuDisabled);

	constructor(protected router: Router, private store: Store) {
		super(router);
	}
}
