<header class="menu-header">
	<div class="menu-icon">
		<button class="menu-toggle" data-cy="ToggleMenu"
				type="button"
				(click)="onToggle()">
			<span></span>
			<span></span>
			<span></span>
		</button>
	</div>

	<div class="menu-logo">
		<img src="/assets/images/logos/logo_aston_ai.png" width="125px" alt="{{ 'App.Name' | translate }} - {{ 'App.Baseline' | translate }}">
	</div>
</header>

<ul #menulinks class="menu-links">
	<li>
		<a data-cy="MenuParent" data-testid="MenuParent.HomePage"
			[routerLink]="routes.homePage"
			routerLinkActive="active">
			<i class="menu-icon fac fa-custom-home"></i>
			<span>{{ 'Menu.TaskBoard' | translate }}</span>
		</a>
	</li>
	<li>
		<a data-cy="MenuParent" data-testid="MenuParent.SellersList"
			[routerLink]="routes.sellersList"
			[class.active]="specialActiveCases(routes.sellersList)">
			<i class="menu-icon fac fa-custom-user"></i>
			<span>{{ 'Menu.Sellers' | translate }}</span>
		</a>
	</li>
	<li>
		<a data-cy="MenuParent" data-testid="MenuParent.SuperDebtorsList"
			[routerLink]="routes.superDebtorsList"
			[class.active]="specialActiveCases(routes.superDebtorsList)">
			<i class="menu-icon fac fa-custom-portfolio"></i>
			<span>{{ 'Menu.SuperDebtors' | translate }}</span>
		</a>
	</li>
	<li>
		<a data-cy="MenuParent" data-testid="MenuParent.TransfersList"
			[routerLink]="routes.transfersList"
			[class.active]="specialActiveCases(routes.transfersList)">
			<i class="menu-icon fac fa-custom-clipboard"></i>
			<span>{{ 'Menu.Transfers.Title' | translate }}</span>
		</a>

		<ul class="submenu-links">
			<li>
				<a data-cy="MenuItem" data-testid="MenuItem.TransfersList"
					[routerLink]="routes.transfersList"
					[class.active]="specialActiveCases(routes.transfersList)">{{ 'Menu.Transfers.Item1' | translate }}</a>
			</li>
			<li *ngIf="hasPaymentOrderClearanceLevel">
				<a data-cy="MenuItem" data-testid="MenuItem.PaymentOrdersList"
					[routerLink]="routes.paymentOrdersList"
					routerLinkActive="active">{{ 'Menu.Transfers.Item2' | translate }}</a>
			</li>
		</ul>
	</li>
	<li>
		<a data-cy="MenuParent" data-testid="MenuParent.AccountingList"
			[routerLink]="routes.accountingList"
			routerLinkActive="active">
			<i class="menu-icon fac fa-custom-paperclip"></i>
			<span>{{ 'Menu.Accounting.Title' | translate }}</span>
		</a>

		<ul class="submenu-links">
			<li>
				<a data-cy="MenuItem" data-testid="MenuItem.AccountingList"
					[routerLink]="routes.accountingList"
					routerLinkActive="active"
					[routerLinkActiveOptions]="{ exact: true }">{{ 'Menu.Accounting.Item1' | translate }}</a>
			</li>
			<li *ngIf="hasLetteringClearanceLevel">
				<a data-cy="MenuItem" data-testid="MenuItem.AccountingLettering"
					[routerLink]="routes.accountingLettering"
					routerLinkActive="active">{{ 'Menu.Accounting.Item2' | translate }}</a>
			</li>
		</ul>
	</li>
	<li>
		<a data-cy="MenuParent" data-testid="MenuParent.Dunning"
			[routerLink]="routes.dunning"
			[class.active]="specialActiveCases(routes.dunning)">
			<i class="menu-icon fac fa-custom-cash"></i>
			<span>{{ 'Menu.Dunning.Title' | translate }}</span>
		</a>

		<ul class="submenu-links">
			<li>
				<a data-cy="MenuItem" data-testid="MenuItem.DunningActionsList"
					[routerLink]="routes.dunningActionsList"
					routerLinkActive="active">{{ 'Menu.Dunning.Item5' | translate }}</a>
			</li>
			<li>
				<a data-cy="MenuItem" data-testid="MenuItem.DunningCasesList"
					[routerLink]="routes.dunningCasesList"
					routerLinkActive="active">{{ 'Menu.Dunning.Item1' | translate }}</a>
			</li>
			<li>
				<a data-cy="MenuItem" data-testid="MenuItem.DunningGroupsList"
					[routerLink]="routes.dunningGroupsList"
					routerLinkActive="active">{{ 'Menu.Dunning.Item2' | translate }}</a>
			</li>
			<li>
				<a data-cy="MenuItem" data-testid="MenuItem.DunningTemplatesList"
					[routerLink]="routes.dunningTemplatesList"
					routerLinkActive="active">{{ 'Menu.Dunning.Item3' | translate }}</a>
			</li>
			<li>
				<a data-cy="MenuItem" data-testid="MenuItem.DunningScenariosList"
					[routerLink]="routes.dunningScenariosList"
					routerLinkActive="active">{{ 'Menu.Dunning.Item4' | translate }}</a>
			</li>
			<li *ngIf="hasDunningClearanceLevel">
				<a data-cy="MenuItem" data-testid="MenuItem.DunningShipmentHistory"
					[routerLink]="routes.dunningShipmentHistory"
					routerLinkActive="active">{{ 'Menu.Dunning.Item6' | translate }}</a>
			</li>
		</ul>
	</li>

	<li>
		<a data-cy="MenuParent"
			[attr.data-testid]="hasManualImportClearanceLevel ? 'MenuParent.ImportCreation' : 'MenuParent.ImportList'"
			[routerLink]="hasManualImportClearanceLevel ? routes.importCreation : routes.importList"
			routerLinkActive="active">
			<i class="menu-icon far fa-file-import"></i>
			<span>{{ 'Menu.Import.Title' | translate }}</span>
		</a>

		<ul class="submenu-links">
			<li *ngIf="hasManualImportClearanceLevel">
				<a data-cy="MenuItem" data-testid="MenuItem.ImportCreation"
					[routerLink]="routes.importCreation"
					routerLinkActive="active">{{ 'Menu.Import.Item1' | translate }}</a>
			</li>
			<li>
				<a data-cy="MenuItem" data-testid="MenuItem.ImportList"
					[routerLink]="routes.importList"
					routerLinkActive="active">{{ 'Menu.Import.Item2' | translate }}</a>
			</li>
		</ul>
	</li>

	<li *ngIf="hasAdministrationToolsClearanceLevel || hasSuperAdministrationClearanceLevel">
		<a data-cy="MenuParent" data-testid="MenuParent.AdminToolsPage"
			[routerLink]="routes.adminToolsPage"
				routerLinkActive="active">
			<i class="menu-icon far fa-cog"></i>
			<span>{{ 'Menu.AdminTools.Title' | translate }}</span>
		</a>

		<ul class="submenu-links">
			@if (hasAdministrationToolsClearanceLevel) {
				<li *featureFlag="'adminUsersPage'">
					<a data-cy="MenuItem" data-testid="MenuItem.AdminToolsUsers"
						[routerLink]="routes.adminToolsUsers"
						routerLinkActive="active">{{ 'Menu.AdminTools.Users' | translate }}</a>
				</li>
				<li>
					<a data-cy="MenuItem" data-testid="MenuItem.AdminToolsCommissionRates"
						[routerLink]="routes.adminToolsCommissionRates"
						routerLinkActive="active">{{ 'Menu.AdminTools.Item14' | translate }}</a>
				</li>
				<li>
					<a data-cy="MenuItem" data-testid="MenuItem.AdminToolsTransfers"
						[routerLink]="routes.adminToolsTransfers"
						routerLinkActive="active">{{ 'Menu.AdminTools.Item1' | translate }}</a>
				</li>
				<li>
					<a data-cy="MenuItem" data-testid="MenuItem.AdminToolsSellerDunning"
						[routerLink]="routes.adminToolsSellerDunning"
						routerLinkActive="active">{{ 'Menu.AdminTools.Item2' | translate }}</a>
				</li>
				<li>
					<a data-cy="MenuItem" data-testid="MenuItem.AdminToolsSuperDebtorDunning"
						[routerLink]="routes.adminToolsSuperDebtorDunning"
						routerLinkActive="active">{{ 'Menu.AdminTools.Item3' | translate }}</a>
				</li>
				<li>
					<a data-cy="MenuItem" data-testid="MenuItem.adminToolsExtradataConfig"
					   [routerLink]="routes.adminToolsExtradataConfig"
					   routerLinkActive="active">{{ 'Menu.AdminTools.Item9' | translate }}</a>
				</li>
			}
			@if (hasSuperAdministrationClearanceLevel) {
				<li>
					<a data-cy="MenuItem" data-testid="MenuItem.AdminToolsWorkers"
						[routerLink]="routes.adminToolsWorkers"
						routerLinkActive="active">{{ 'Menu.AdminTools.Item4' | translate }}</a>
				</li>
				<li>
					<a data-cy="MenuItem" data-testid="MenuItem.AdminToolsWorkflowSchedulingInstancesList"
						[routerLink]="routes.adminToolsWorkflowSchedulingInstancesList"
						routerLinkActive="active">{{ 'Menu.AdminTools.Item5' | translate }}</a>
				</li>
				<li>
					<a data-cy="MenuItem" data-testid="MenuItem.AdminToolsWorkflowSchedulingConfig"
						[routerLink]="routes.adminToolsWorkflowSchedulingConfig"
						routerLinkActive="active">{{ 'Menu.AdminTools.Item6' | translate }}</a>
				</li>
				<li>
					<a data-cy="MenuItem" data-testid="MenuItem.AdminToolsWorkflowSchedulingLauncher"
						[routerLink]="routes.adminToolsWorkflowSchedulingLauncher"
						routerLinkActive="active">{{ 'Menu.AdminTools.Item7' | translate }}</a>
				</li>
			}
		</ul>
	</li>
</ul>
