import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { NotFoundPageComponent } from '@aston/foundation';
import { FeatureGuard } from '@aston/feature-flags';

import * as RoutesDefinitions from './routes-definitions';
import { MasterLayoutComponent } from './shared-module/layouts/master-layout/master-layout.component';
import { LoginPageComponent } from './authentication-module/pages/login-page/login-page.component';
import { LoginCallbackPageComponent } from './authentication-module/pages/callback-page/callback-page.component';
import { AuthenticationLayoutComponent } from './authentication-module/layouts/authentication-layout/authentication-layout.component';
import { AuthenticationGuard, AnonymousGuard } from './authentication-module/guards';
import { AuthenticationIssuePageComponent } from './authentication-module/pages/authent-issue-page/authentication-issue-page.component';

// using RoutesDefinitions.getLoginFullPath() generates an infinite loop
const loginRoutePath = RoutesDefinitions.AuthenticationRoutePath + '/' + RoutesDefinitions.LoginRoutePath;


const routes: Routes = [
	// Redirects to login page
	{path: '', pathMatch: 'prefix', redirectTo: loginRoutePath},

	// Authentication process pages
	{
		path: RoutesDefinitions.AuthenticationRoutePath, component: AuthenticationLayoutComponent,
		children: [
			{
				path: RoutesDefinitions.LoginRoutePath, component: LoginPageComponent, canActivate: [AnonymousGuard],
				data: {titleTranslationKey: 'Login', layoutName: 'login'}
			},
			{
				path: RoutesDefinitions.AuthenticationIssueRoutePath, component: AuthenticationIssuePageComponent,
				data: {titleTranslationKey: 'Login', layoutName: 'authentication-issue'}
			},

			{
				path: RoutesDefinitions.LoginCallbackRoutePath, component: LoginCallbackPageComponent,
				data: {titleTranslationKey: 'Login', layoutName: 'login'}
			},
		]
	},

	// Authenticated service pages
	{
		path: '', 
		component: MasterLayoutComponent,
		canActivate: [AuthenticationGuard],
		data: {titleTranslationKey: 'Root'},
		children: [

			{
				path: 'not-found',
				component: NotFoundPageComponent,
				data: {titleTranslationKey: 'NotFound'},
			},
		]
	},

	// Authenticated pages
	{
		path: '', 
		component: MasterLayoutComponent,
		canActivate: [AuthenticationGuard],
		data: {titleTranslationKey: 'Root'},
		children: [
			{
				path: RoutesDefinitions.PlatformHomePageRoutePath,
				data: { titleTranslationKey: 'Home', },
				children: [
					{
						path: RoutesDefinitions.TasksPageRoutePath,
						canLoad: [FeatureGuard],
						data: { feature: 'tasksHomepage', featureFallback: RoutesDefinitions.getHomeFullPath() },
						loadChildren: () => import('./tasks-module/tasks.module'),
					},
					{
						path: '',
						canLoad: [FeatureGuard],
						data: { feature: 'chartsHomepage', featureFallback: RoutesDefinitions.getTasksFullPath() },
						loadChildren: () => import('./home-module/home-routing.module'),
					},
				]
			},
			{
				path: RoutesDefinitions.AccountingDocsListRoutePath,
				loadChildren: () => import('./accounting-module/accounting-routing.module')
			},
			{
				path: RoutesDefinitions.DunningRoutePath,
				loadChildren: () => import('./dunnings-module/dunnings-routing.module')
			},
			{
				path: RoutesDefinitions.DunningSettingRoutePath,
				loadChildren: () => import('./dunning-settings-module/dunning-settings-routing.module')
			},
			{
				path: RoutesDefinitions.ImportManualFullRoutePath,
				loadChildren: () => import('./import-manual-module/import-manual.module')
			},
			{
				path: RoutesDefinitions.ImportPath,
				loadChildren: () => import('./import-module/import-routing.module')
			},
			{
				path: RoutesDefinitions.AdminToolsPath,
				loadChildren: () => import('./admin-module/admin.module')
			},
			{
				path: RoutesDefinitions.SuperDebtorsListRoutePath,
				loadChildren: () => import('./debtors-module/debtors-routing.module')
			},
			{
				path: RoutesDefinitions.SellersListRoutePath,
				loadChildren: () => import('./sellers-module/sellers.module')
			},
			{
				path: RoutesDefinitions.TransfersListRoutePath,
				loadChildren: () => import('./transfers-module/transfers-routing.module')
			},
			{
				path: RoutesDefinitions.NotificationsDashboardRoutePath,
				loadChildren: () => import('./notifications-module/notifications.module')
			},
		]
	},

	// Default redirection
	{path: '**', redirectTo: loginRoutePath}
];

@NgModule({
	imports: [RouterModule.forRoot(routes,
		{enableTracing: false}
	)],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
