<page-header *featureFlag="'topBarTitle'; else: titlePlaceholder" class="tenant-title"
	pageTitleKey="TenantName"
	[pageTitle]="tenantName | uppercase">
	<div page-header-area="icon" class="tenant-logo" *ngIf="tenantLogo"
		[style.background-image]="'url('+tenantLogo+')'"></div>
</page-header>
<ng-template #titlePlaceholder>
	<div class="tenant-title title-placeholder"></div>
</ng-template>

<div class="user-actions">
	<multi-search-input
		[groups]="searchGroups()"
		[results]="searchResultsWithMore()"
		(search)="search($event)"
		(pick)="pickSearchResult($event)"
	/>

	<button data-cy="OpenNotifications" class="btn-notifications btn-badged" (click)="toggleNotificationsPanel()">
		<i class="fal fa-bell" ngbTooltip="{{'TopBar.Tooltip.Notifications' | translate}}" placement="left" container="body"></i>
		<span *ngIf="notifications.unreadCount() as unread" class="badge badge-pill badge-secondary">{{(unread <= 9) ? unread : '9+' }}</span>
	</button>
	<button data-cy="OpenHelp" class="btn-help" *featureFlag="'topBarHelp'" (click)="openHelp()">
		<i class="fal fa-question-circle" ngbTooltip="{{'TopBar.Tooltip.Help' | translate}}" placement="left" container="body"></i>
	</button>
	<div data-cy="OpenLanguages" class="user-languages" ngbDropdown placement="bottom-right" *ngIf="hasTranslations" (openChange)="userMenuOpened()">
		<button class="btn btn-primary" ngbDropdownToggle data-cy="DropdownToggle">
			{{ currentLanguage | language : true }}
		</button>
		<div class="dropdown-menu" ngbDropdownMenu>
			<button data-cy="SelectLanguage" (click)="setLanguage(fr)" class="dropdown-item">FR</button>
			<button data-cy="SelectLanguage" (click)="setLanguage(en)" class="dropdown-item">EN</button>
		</div>
	</div>

	<div class="user-account" *ngIf="!!user" ngbDropdown placement="bottom-right" (openChange)="userMenuOpened()">
		<user-avatar [user]="user" ngbDropdownToggle data-cy="DropdownToggle" class="no-carret"/>
		<div ngbDropdownMenu>
			<ng-container *ngIf="operator$ | async as operator">
				<button *ngIf="!operator.isSupport" class="dropdown-item" data-cy="EditMyInfos" (click)="editMyInformations()">
					<i class="mr-2 fa fa-user"></i> {{ 'TopBar.Account.MyAccount' | translate }}</button>
			</ng-container>
			<button data-cy="OpenThemes" class="dropdown-item" (click)="toggleThemesPanel()">
				<i class="mr-2 fal fa-palette"></i> {{ 'App.Themes.Title' | translate }}</button>
			<button *ngIf="canUpdatePlatform$ | async" data-cy="OpenUpdatePlatform" class="dropdown-item" (click)="updatePlatform()">
				<i class="mr-2 fa fa-sync-alt"></i> {{ 'TopBar.Account.UpdatePlatform' | translate }}</button>
			<button *ngIf="(config$ | async).consoleUrl" data-cy="LeavePlatform" class="dropdown-item" (click)="leavePlatform()">
				<i class="mr-2 fa fa-door-open"></i> {{ 'TopBar.Account.Leave' | translate }}</button>
			<a *ngIf="(config$ | async).partnershipsUrl as partnershipsUrl" [href]="partnershipsUrl" data-cy="OpenPartnerships" class="dropdown-item">
				<i class="mr-2 fa fa-handshake"></i> {{ 'TopBar.Account.Partnerships' | translate }}</a>
			<button data-cy="Logout" class="dropdown-item" (click)="userLogout()">
				<i class="mr-2 fa fa-sign-out-alt"></i> {{ 'TopBar.Account.LogOut' | translate }}</button>
		</div>
	</div>
</div>
